import { useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { fetchSearchResults } from '../api/userSearch';
import { useForm } from 'react-hook-form';
import { FaTimes } from 'react-icons/fa';


const UserSearch = ({
    modifiedMemberTypeList,
    selectedMemberType,
    setSelectedMemberType,
    searchName,
    setSearchName,
    searchEmail,
    setSearchEmail,
    searchAgeMin,
    setSearchAgeMin,
    searchAgeMax,
    setSearchAgeMax,
    selectedPrefecture,
    setSelectedPrefecture,
    setIsSearchIconClicked,
    loadMoreData,
    styles,
    handleDetailSearch
}) => {
    // const navigate = useNavigate();
    const { register, formState: { errors } } = useForm();


    const prefectures = [
        { name: '北海道' }, { name: '青森県' }, { name: '岩手県' }, { name: '宮城県' },
        { name: '秋田県' }, { name: '山形県' }, { name: '福島県' }, { name: '茨城県' },
        { name: '栃木県' }, { name: '群馬県' }, { name: '埼玉県' }, { name: '千葉県' },
        { name: '東京都' }, { name: '神奈川県' }, { name: '新潟県' }, { name: '富山県' },
        { name: '石川県' }, { name: '福井県' }, { name: '山梨県' }, { name: '長野県' },
        { name: '岐阜県' }, { name: '静岡県' }, { name: '愛知県' }, { name: '三重県' },
        { name: '滋賀県' }, { name: '京都府' }, { name: '大阪府' }, { name: '兵庫県' },
        { name: '奈良県' }, { name: '和歌山県' }, { name: '鳥取県' }, { name: '島根県' },
        { name: '岡山県' }, { name: '広島県' }, { name: '山口県' }, { name: '徳島県' },
        { name: '香川県' }, { name: '愛媛県' }, { name: '高知県' }, { name: '福岡県' },
        { name: '佐賀県' }, { name: '長崎県' }, { name: '熊本県' }, { name: '大分県' },
        { name: '宮崎県' }, { name: '鹿児島県' }, { name: '沖縄県' }
    ];

    //会員区分が変更された場合
    const handleMemberTypeChange = (type) => {
        setSelectedMemberType(type);
    };

    const handleScroll = useCallback((e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight * 0.8) {
            loadMoreData({
                name: searchName,
                email: searchEmail,
                ageMin: searchAgeMin,
                ageMax: searchAgeMax,
                prefecture: selectedPrefecture,
                selectedMemberType
            });
        }
    }, [loadMoreData, searchName, searchEmail, searchAgeMin, searchAgeMax, selectedPrefecture, selectedMemberType]);

    useEffect(() => {
        const scrollableElement = document.querySelector('.ScrollableList');
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    const handleSearchNameChange = (e) => {
        setSearchName(e.target.value);
    };

    const handleSearchEmailChange = (e) => {
        setSearchEmail(e.target.value);
    };

    const handleSearchAgeMinChange = (e) => {
        setSearchAgeMin(e.target.value);
    };

    const handleSearchAgeMaxChange = (e) => {
        setSearchAgeMax(e.target.value);
    };

    const handlePrefectureChange = (e) => {
        setSelectedPrefecture(e.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // エンターキーのデフォルト動作をキャンセル
        }
    };

    return (
        <>
            <div className={styles.ModalBackground}>
                <form onSubmit={(event) => event.preventDefault()} className={styles.SearchForm}>
                    <div className={styles.SearchHeader}>
                        <div className={styles.SearchTitle}>
                            ユーザー検索
                        </div>  
                        <button className={styles.CloseButton} onClick={() => setIsSearchIconClicked(false)}>
                            <FaTimes />
                        </button>
                    </div>
                    <div className={styles.SearchTermsContainer}>
                        <label className={styles.SearchLabel}>ユーザー名</label>
                        <input
                            type="text"
                            className={styles.SearchNameInput}
                            placeholder='ユーザー名で検索...'
                            name='name'
                            {...register('name', {
                                maxLength: {
                                    value: 64,
                                    message: '姓は64文字以内で入力してください'
                                }
                            })}
                            onChange={handleSearchNameChange}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    {errors.name && <p>{errors.name.message}</p>}
                    <div className={styles.SearchTermsContainer}>
                        <label className={styles.SearchLabel}>メールアドレス</label>
                        <input
                            type="text"
                            className={styles.SearchEmailInput}
                            placeholder='メールアドレスで検索...'
                            name='email'
                            {...register('email', {
                                maxLength: {
                                    value: 256,
                                    message: 'メールアドレスは256文字以内で入力してください'
                                },
                                pattern: {
                                    value: /^[\x20-\x7E]+$/,
                                    message: 'メールアドレスは半角文字で入力してください'
                                }
                            })}
                            onChange={handleSearchEmailChange}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    {errors.email && <p>{errors.email.message}</p>}
                    <div className={styles.SearchTermsContainer}>
                        <label className={styles.SearchLabel}>年齢</label>
                        <input
                            type="number"
                            className={styles.SearchAgeInput}
                            placeholder='年齢で検索...'
                            name='ageMin'
                            min="0"
                            {...register('ageMin', {
                                min: {
                                    value: 0,
                                    message: '年齢は0以上で入力してください'
                                }
                            })}
                            onChange={handleSearchAgeMinChange}
                            onKeyDown={handleKeyDown}
                        />
                        <div className={styles.SearchAgeUnit}>歳から</div>
                        <input
                            type="number"
                            className={styles.SearchAgeInput}
                            placeholder='歳まで'
                            name='ageMax'
                            min="0"
                            {...register('ageMax', {
                                min: {
                                    value: 0,
                                    message: '年齢は0以上で入力してください'
                                }
                            })}
                            onChange={handleSearchAgeMaxChange}
                            onKeyDown={handleKeyDown}
                        />
                        <div className={styles.SearchAgeUnit}>歳まで</div>
                    </div>
                    {(errors.ageMax || errors.ageMin) && <p>{errors.ageMax?.message || errors.ageMin?.message}</p>}
                    <div className={styles.SearchTermsContainer}>
                        <label className={styles.SearchLabel}>メンバータイプ</label>
                        <select
                            className={styles.SearchSelect}
                            value={selectedMemberType.memberTypeCode}
                            onChange={(e) => handleMemberTypeChange(e.target.value)}
                        >
                            {modifiedMemberTypeList.map((type) => (
                                <option key={type.memberTypeCode} value={type.memberTypeCode}>
                                    {type.memberTypeName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.SearchTermsContainer}>
                        <label className={styles.SearchLabel}>都道府県</label>
                        <select
                            className={styles.SearchSelect}
                            value={selectedPrefecture.name}
                            onChange={handlePrefectureChange}
                        >
                            <option value="">選択してください</option>
                            {prefectures.map((prefecture) => (
                                <option key={prefecture.name} value={prefecture.name}>
                                    {prefecture.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        className={styles.SearchButton}
                        type="button"
                        onClick={() => handleDetailSearch()}
                    >
                        検索
                    </button>
                </form>
            </div>
        </>
    );
};

export default UserSearch;
