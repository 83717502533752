import React from 'react';
import ContentCard from './ContentCard';
import commonStyles from '../css/Common.module.css';
import styles from '../css/RecentQuestionPage.module.css';

const RecentQuestionPage = () => {


    return (
        <ContentCard>
            <div className={commonStyles.HeaderBox}>
                <div className={commonStyles.ContentHeader}>
                    <div className={commonStyles.ContentTitleLeft}>
                        <div className={commonStyles.TitleText}>
                            最近のAI質問
                        </div>
                        <div className={commonStyles.subTitle}>
                            利用者とAIのやり取りを検索できます。
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.SearchBox}>
                <div className={styles.SearchInput}>
                    <input type="text" placeholder="質問を入力してください" className={styles.InputArea} />
                </div>
                <div className={styles.SearchButton}>
                    <button className={styles.Button}>検索</button>
                </div>
            </div>
        </ContentCard>
    );
};

export default RecentQuestionPage;