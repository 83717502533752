const formatDateString = (dateString) => {

    if (!dateString) {
        return '日付取得エラー';
    }

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return '日付変換エラー';
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const dateStr = `${year}/${month}/${day}`;
    const timeStr = `${hours}:${minutes}`;

    return `${dateStr} ${timeStr}`;
};

export default formatDateString;

