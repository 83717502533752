import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/Dashboard.module.css';
import commonStyles from '../css/Common.module.css';
import { fetchDashboardData } from '../api/dashboard';
import ContentCard from './ContentCard';
import TotalUserCard from './Card_TotalUser';
import TotalQuestionCard from './Card_TotalQuestion';
import RecentRegistrationCard from './Card_RecentRegistration';
import RecentQuestionCard from './Card_RecentQuestion';
import {
    handleMouseMove,
    handleResizeStart,
    handleMouseUp,
    addResizeEventListeners,
    removeResizeEventListeners
} from '../utils/ResizeUtils';


const Dashboard = () => {
    const navigate = useNavigate();
    //Stateを用いてデータを管理
    const [dashboardData, setDashboardData] = useState({
        userCount: 0,
        totalQuestions: 0,
        recentUsers: [],
        recentQuestions: []
    });

    const isResizing = useRef(false);
    const listRef = useRef(null);

    //同じ質問をまとめる
    const uniqueQuestions = Array.from(new Set(dashboardData?.recentQuestions.map(question => question.query)))
        .map(query => dashboardData?.recentQuestions.find(question => question.query === query));

    //ダッシュボードデータを取得
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchDashboardData(navigate);
                setDashboardData(data);
            } catch (error) {
                console.error('ダッシュボードデータ取得エラー:', error);
            }
        };
        fetchData();
    }, [navigate]);

    //リサイズ
    useEffect(() => {
        const moveHandler = (e) => handleMouseMove(e, listRef, isResizing);
        const upHandler = () => handleMouseUp(isResizing);

        addResizeEventListeners(moveHandler, upHandler);

        return () => {
            removeResizeEventListeners(moveHandler, upHandler);
        };
    }, []);

    return (
        <div className={commonStyles.WholeContainer}>

            {/* タイトル */}
            <div className={commonStyles.TitleContainer}>
                <div className={commonStyles.TitleText}>ダッシュボード</div>
                <div className={commonStyles.subTitle}>利用者の大まかな統計情報が確認できます</div>
            </div>

            {/* 総ユーザー数、総質問数 */}
            <div className={styles.Content1}>
                <div className={styles.ContentCardPosition}>
                    <ContentCard>
                        <TotalUserCard userCount={dashboardData?.userCount} />
                    </ContentCard>
                </div>
                <div className={styles.ContentCardPosition}>
                    <ContentCard>
                        <TotalQuestionCard totalQuestions={dashboardData?.totalQuestions} />
                    </ContentCard>
                </div>
            </div>

            {/* 最近のユーザー登録、最近の質問 */}
            <div className={styles.Content2}>
                <div className={styles.ContentCardPosition}>
                    <ContentCard>
                        <RecentRegistrationCard recentUsers={dashboardData?.recentUsers} listRef={listRef} handleResizeStart={handleResizeStart} />
                    </ContentCard>
                </div>
                <div className={styles.ContentCardPosition}>
                    <ContentCard>
                        <RecentQuestionCard dashboardData={dashboardData} uniqueQuestions={uniqueQuestions} />
                    </ContentCard>
                </div>
            </div>
        </div >
    );
};

export default Dashboard;