import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import styles from '../css/EditUser.module.css';
import ContentCard from './ContentCard';
import EditSearchUser from './EditSearchUser';
import { updateUser } from '../api/editUser';
import NotificationModal from './NotificationModal';
import formatDateString from '../utils/DateUtils';
import { FaTimes } from 'react-icons/fa';
const EditUser = () => {
    const navigate = useNavigate();
    //検索結果のメッセージ
    const [searchResultMessage, setSearchResultMessage] = useState('');
    //編集画面を開くか否か
    const [openEditor, setOpenEditor] = useState(false);
    //選択したユーザーの情報
    const [selectedUserDetail, setSelectedUserDetail] = useState(null);
    //メンバータイプ
    const { memberTypeList } = useOutletContext();
    const [memberTypeCode, setMemberTypeCode] = useState('');
    //ユーザー情報
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastNameKana, setLastNameKana] = useState('');
    const [firstNameKana, setFirstNameKana] = useState('');
    const [mail, setMail] = useState('');
    //選択したユーザー
    const [selectedUser, setSelectedUser] = useState([]);
    //通知モーダル
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    //メンバータイプのドロップダウン
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);



    // ローカルストレージから取得する系
    useEffect(() => {
        // ローカルストレージに通知メッセージがある場合は表示
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage'); // 使用後は削除
        }
    }, []);

    const handleIndividSubmit = async (e) => {
        e.preventDefault();

        // 個別編集の場合は長さ1の配列を渡す
        //一括編集の場合は長さnの配列を渡し、同じapiを用いるため

        const userData = [{
            id: selectedUserDetail.id,
            lastName,
            firstName,
            lastNameKana,
            firstNameKana,
            mail,
            memberTypeCode
        }];

        // -------入力値のバリデーションチェック-------

        // 未入力の項目があるかチェック
        if (userData.some(user =>
            user.lastName === '' ||
            user.firstName === '' ||
            user.lastNameKana === '' ||
            user.firstNameKana === '' ||
            user.mail === ''
        )) {
            setNotificationMessage('未入力の項目があります');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        // 全角カタカナ、全角ひらがな、漢字のみ許可（全角数字は弾く）
        const fullWidthRegex = /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/;
        if (!fullWidthRegex.test(lastName) || !fullWidthRegex.test(firstName)) {
            setNotificationMessage('苗字と名前は全角文字で入力してください');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        // 名前のカナに半角が含まれていないかチェック
        const noHalfWidthKanaRegex = /^[\u30A0-\u30FF]+$/;
        if (!noHalfWidthKanaRegex.test(lastNameKana) || !noHalfWidthKanaRegex.test(firstNameKana)) {
            setNotificationMessage('カナは全角カナで入力してください');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        // メールアドレスがメールアドレスの形式かチェック
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(mail)) {
            setNotificationMessage('メールアドレスの形式が不正です');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        // 変更がない場合のバリデーションチェック
        if (userData.some(user => (
            user.lastName === selectedUserDetail.lastName &&
            user.firstName === selectedUserDetail.firstName &&
            user.lastNameKana === selectedUserDetail.lastNameKana &&
            user.firstNameKana === selectedUserDetail.firstNameKana &&
            user.mail === selectedUserDetail.mail &&
            user.memberTypeCode === selectedUserDetail.memberTypeCode
        ))) {
            setNotificationMessage('変更がありません');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        // -----------------------------------------

        const response = await updateUser(navigate, userData);
        if (response) {
            setNotificationMessage(response.message);
            setNotificationType(response.success ? 'success' : 'error');
            setIsNotificationOpen(true);

            // 成功した場合はローカルストレージに通知メッセージを保存し、リロード
            if (response.success) {
                localStorage.setItem('notificationMessage', response.message);
                window.location.reload();
            }
        }
    };

    useEffect(() => {

        // 選択したユーザーの情報をセット
        if (selectedUserDetail) {
            setLastName(selectedUserDetail.lastName || '');
            setFirstName(selectedUserDetail.firstName || '');
            setLastNameKana(selectedUserDetail.lastNameKana || '');
            setFirstNameKana(selectedUserDetail.firstNameKana || '');
            setMail(selectedUserDetail.mail || '');
            setMemberTypeCode(selectedUserDetail.memberTypeCode || '');
        }
    }, [selectedUserDetail]);

    // メンバータイプのドロップダウンを開く
    const handleDropdownToggle = (e) => {
        e.stopPropagation(); // イベントの伝播を防ぐ
        e.preventDefault();  // デフォルトの動作を防ぐ
        setIsDropdownOpen(!isDropdownOpen);
    };

    // メンバータイプを選択
    const handleSelectType = (type, e) => {
        e.stopPropagation(); // イベントの伝播を防ぐ
        e.preventDefault();  // デフォルトの動作を防ぐ
        setMemberTypeCode(type.memberTypeCode);
        setIsDropdownOpen(false);
    };

    return (
        <>
            <div className={styles.EditUser}>
                <div className={styles.SearchTitle}>
                    <div className={styles.TitleContainer}>
                        <h3 className={styles.Title}>ユーザー情報編集</h3>
                        <div className={styles.SubTitle}>利用者の登録情報を編集できます</div>
                    </div>
                </div>
                <div className={styles.SearchContainer}>
                    <div className={`${styles.SearchArea} ${openEditor ? styles.open : styles.close}`}>
                        <EditSearchUser
                            searchResultMessage={searchResultMessage}
                            setSearchResultMessage={setSearchResultMessage}
                            setOpenEditor={setOpenEditor}
                            setSelectedUserDetail={setSelectedUserDetail}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                        />
                    </div>
                    <div className={`${styles.EditArea} ${openEditor ? styles.open : styles.close}`}>
                        <div className={styles.EditContainer}>
                            <div className={styles.EditCard}>
                                <ContentCard>
                                    <>
                                        <div className={`${styles.closeButton} ${openEditor ? styles.open : styles.close}`} onClick={() => setOpenEditor(false)}>
                                            <FaTimes size={20} />
                                        </div>
                                        <form onSubmit={handleIndividSubmit} className={styles.EditForm}>
                                            <div className={styles.ModalBody}>
                                                <div className={styles.NameZone}>
                                                    <div className={styles.ZoneItem}>
                                                        <label>姓</label>
                                                        <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                    </div>
                                                    <div className={styles.ZoneItem}>
                                                        <label>名</label>
                                                        <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className={styles.NameKanaZone}>
                                                    <div className={styles.ZoneItem}>
                                                        <label>姓(カナ)</label>
                                                        <input type="text" value={lastNameKana} onChange={(e) => setLastNameKana(e.target.value)} />
                                                    </div>
                                                    <div className={styles.ZoneItem}>
                                                        <label>名(カナ)</label>
                                                        <input type="text" value={firstNameKana} onChange={(e) => setFirstNameKana(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className={styles.ZoneItem}>
                                                    <label>メールアドレス</label>
                                                    <input type="text" value={mail} onChange={(e) => setMail(e.target.value)} />
                                                </div>
                                                <div className={styles.ZoneItem}>
                                                    <label>メンバータイプ</label>
                                                    <div className={styles.DropdownContainer}>
                                                        <button onClick={handleDropdownToggle} className={styles.DropdownButton}>
                                                            {memberTypeList?.find(type => type.memberTypeCode === memberTypeCode)?.memberTypeName || '選択してください'} ▾
                                                        </button>
                                                        {isDropdownOpen && (
                                                            <div className={`${styles.DropdownMenu} ${styles.DropdownMenuOpen}`}>
                                                                {memberTypeList?.map((type) => (
                                                                    <div
                                                                        key={type.memberTypeCode}
                                                                        className={styles.DropdownMenuItem}
                                                                        onClick={(e) => handleSelectType(type, e)}
                                                                    >
                                                                        {type.memberTypeName}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={styles.ZoneItem}>
                                                    <label>作成日時</label>
                                                    <div>{formatDateString(selectedUserDetail?.createDate)}</div>
                                                </div>
                                                <div className={styles.ButtonZone}>
                                                    <button type="submit">更新</button>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                </ContentCard>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
        </>
    );
};

export default EditUser;
