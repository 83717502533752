import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import styles from '../css/UserDetail.module.css';
import UserSearchStyles from '../css/UserSearch.module.css';
// import commonStyles from '../css/Common.module.css';
import UserSearch from './UserSearch';
import UserPage from './UserPage';
import { FaSearch } from 'react-icons/fa';
import { fetchSearchResults } from '../api/userSearch';
import formatDate from '../utils/DateUtils';
import { useNavigate } from 'react-router-dom';

const UserDetail = () => {
    const navigate = useNavigate();
    const { memberTypeList } = useOutletContext();
    const [modifiedMemberTypeList, setModifiedMemberTypeList] = useState([]);
    //会員区分の選択
    const [selectedMemberType, setSelectedMemberType] = useState('00');

    //会員区分の取得
    useEffect(() => {
        const modifiedTypes = [
            { memberTypeCode: '00', memberTypeName: '全て' },
            ...memberTypeList
        ];
        setModifiedMemberTypeList(modifiedTypes);
        setSelectedMemberType(modifiedTypes[0]?.memberTypeCode || '00');
    }, [memberTypeList, setSelectedMemberType]);

    //ユーザー詳細ページのチャンネル選択
    const [selectedChannel, setSelectedChannel] = useState(0);
    //ユーザー詳細ページのデータ
    const [selectedUserDetail, setSelectedUserDetail] = useState(null);
    //ユーザー詳細ページの表示
    const [openUserPage, setOpenUserPage] = useState(false);
    //詳細検索アイコンのクリック
    const [isSearchIconClicked, setIsSearchIconClicked] = useState(false);
    //検索結果
    const [searchResults, setSearchResults] = useState([]);
    //検索名
    const [searchName, setSearchName] = useState('');
    //検索メールアドレス
    const [searchEmail, setSearchEmail] = useState('');
    //検索年齢最小
    const [searchAgeMin, setSearchAgeMin] = useState('');
    //検索年齢最大
    const [searchAgeMax, setSearchAgeMax] = useState('');
    //検索都道府県
    const [selectedPrefecture, setSelectedPrefecture] = useState('');
    //ページ
    const [page, setPage] = useState(1);
    //クイック検索入力
    const [quickSearchInput, setQuickSearchInput] = useState('');

    const userPageRef = useRef(null);
    const onSearchRef = useRef();

    // State for displayed search conditions
    const [displayedSearchName, setDisplayedSearchName] = useState('');
    const [displayedSearchEmail, setDisplayedSearchEmail] = useState('');
    const [displayedSearchAgeMin, setDisplayedSearchAgeMin] = useState('');
    const [displayedSearchAgeMax, setDisplayedSearchAgeMax] = useState('');
    const [displayedSelectedPrefecture, setDisplayedSelectedPrefecture] = useState('');
    const [displayedSelectedMemberType, setDisplayedSelectedMemberType] = useState('00');

    // Function to update displayed search conditions
    const updateDisplayedSearchConditions = useCallback((data) => {
        setDisplayedSearchName(data.name);
        setDisplayedSearchEmail(data.email);
        setDisplayedSearchAgeMin(data.ageMin);
        setDisplayedSearchAgeMax(data.ageMax);
        setDisplayedSelectedPrefecture(data.prefecture);
        setDisplayedSelectedMemberType(selectedMemberType);
    }, [setDisplayedSearchName,
        setDisplayedSearchEmail,
        setDisplayedSearchAgeMin,
        setDisplayedSearchAgeMax,
        setDisplayedSelectedPrefecture,
        setDisplayedSelectedMemberType,
        selectedMemberType
    ]);

    //特定ユーザーがクリックされた場合
    const handleDetail = useCallback((data) => {
        if (selectedUserDetail && selectedUserDetail.id === data.id) {
            // 同じユーザーがクリックされた場合、ページを閉じる
            setSelectedUserDetail(null);
            setOpenUserPage(false);
        } else {
            // 異なるユーザーがクリックされた場合
            setSelectedUserDetail(data);
            setOpenUserPage(true);
            setSelectedChannel('0');
        }
    }, [selectedUserDetail]);

    //クリックを検知してUserPageを閉じる
    const handleClickOutside = useCallback((event) => {
        if (userPageRef.current && !userPageRef.current.contains(event.target)) {
            setOpenUserPage(false);
            setSelectedUserDetail(null);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    //スクロール時に追加データを取得
    const loadMoreData = async (searchParams) => {
        try {
            const nextPage = page + 1;
            const results = await fetchSearchResults(
                navigate,
                searchParams.name,
                searchParams.email,
                searchParams.ageMin,
                searchParams.ageMax,
                searchParams.prefecture,
                searchParams.selectedMemberType,
                nextPage
            );
            if (results.length > 0) {
                setSearchResults((prevResults) => [...prevResults, ...results]);
                setPage(nextPage);
            }
        } catch (error) {
            console.error('APIエラー：追加データの取得', error);
        }
    };

    //スクロールを検知
    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight * 1.1 ) {
            loadMoreData({ name: searchName, email: searchEmail, ageMin: searchAgeMin, ageMax: searchAgeMax, prefecture: selectedPrefecture, selectedMemberType });
        }
    };

    // 誕生日から年齢を計算する関数
    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDiff = today.getMonth() - birth.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
            age--;
        }
        return age;
    }

    const searchResultRef = useRef(null); // Ref for the search result container

    const onSearch = async (data) => {
        setPage(1);
        try {
            const response = await fetchSearchResults(
                navigate,
                data.name,
                data.email,
                data.ageMin,
                data.ageMax,
                selectedPrefecture,
                selectedMemberType,
                1
            );
            if (response.status === 401) {
                navigate('/login');
            }else{
                setIsSearchIconClicked(false);
                setSearchResults(response);
                updateDisplayedSearchConditions(data);
                if (searchResultRef.current) {
                    searchResultRef.current.scrollTop = 0; // Scroll to top
                }
            }
        } catch (error) {
            console.error('APIエラー：検索結果の取得', error);
            navigate('/error', { state: { statusCode: '', errorMessage: '検索結果の取得に失敗しました' } });
        }
    };

    onSearchRef.current = onSearch;

    useEffect(() => {
        setPage(1);
        onSearchRef.current({
            name: '',
            email: '',
            ageMin: '',
            ageMax: '',
            prefecture: '',
            selectedMemberType: '00'
        });
    }, []);

    const handleQuickSearch = (e) => {
        e.preventDefault();
        onSearch({
            name: quickSearchInput,
            email: '',
            ageMin: '',
            ageMax: '',
            prefecture: '',
            selectedMemberType: '00'
        });
    };

    const handleDetailSearch = () => {
        onSearch({
            name: searchName,
            email: searchEmail,
            ageMin: searchAgeMin,
            ageMax: searchAgeMax,
            prefecture: selectedPrefecture,
            selectedMemberType
        });
    };

    const handleUserSearch = () => {
        setIsSearchIconClicked(!isSearchIconClicked);
        setSelectedPrefecture('');
        setSelectedMemberType('00');
    };

    return (
        <div className={styles.WholeContainer}>
            {/* ユーザー検索 */}
            <div className={styles.UserSearchHeader}>
                <div className={styles.UserSearchTitleContainer}>
                    <div className={styles.UserSearchTitle}>
                        <span className={styles.UserSearchTitleText}>ユーザー検索</span>
                    </div>
                    <div className={styles.SearchTermContainer}>
                        {!displayedSearchName && !displayedSearchEmail && !displayedSearchAgeMin && !displayedSearchAgeMax && !displayedSelectedPrefecture && displayedSelectedMemberType === '00' && (
                            <div className={styles.SearchTerm}>全件検索</div>
                        )}
                        {displayedSearchName && searchResults && <div className={styles.SearchTerm}>ユーザー名: {displayedSearchName}</div>}
                        {displayedSearchEmail && searchResults && <div className={styles.SearchTerm}>メールアドレス: {displayedSearchEmail}</div>}
                        {(displayedSearchAgeMin || displayedSearchAgeMax) && searchResults && <div className={styles.SearchTerm}>年齢: {displayedSearchAgeMin}歳から{displayedSearchAgeMax}歳まで</div>}
                        {displayedSelectedPrefecture && searchResults && <div className={styles.SearchTerm}>都道府県: {displayedSelectedPrefecture}</div>}
                        {displayedSelectedMemberType && searchResults && <div className={styles.SearchTerm}>会員区分: {modifiedMemberTypeList.find(type => type.memberTypeCode === displayedSelectedMemberType)?.memberTypeName}</div>}
                    </div>
                </div>
                <form className={styles.QuickSearchContainer} onSubmit={handleQuickSearch}>
                    <input
                        type="text"
                        className={styles.QuickSearchInput}
                        placeholder="ユーザー名を入力"
                        name="quickSearchInput"
                        value={quickSearchInput}
                        onChange={(e) => setQuickSearchInput(e.target.value)}
                    />
                    <button className={styles.QuickSearchButton} type="submit">
                        名前検索
                    </button>
                </form>
                <div className={styles.UserSearchIconContainer}>
                    <div className={styles.UserSearchIcon} onClick={() => handleUserSearch()}>
                        <div className={styles.UserSearchIconText}>
                            詳細検索
                        </div>
                        <div className={styles.UserSearchIconIcon}>
                            <FaSearch />
                        </div>
                    </div>
                </div>
            </div>
            {isSearchIconClicked && (
                <UserSearch
                    modifiedMemberTypeList={modifiedMemberTypeList}
                    selectedMemberType={selectedMemberType}
                    setSelectedMemberType={setSelectedMemberType}
                    setSearchResults={setSearchResults}
                    searchName={searchName}
                    setSearchName={setSearchName}
                    searchEmail={searchEmail}
                    setSearchEmail={setSearchEmail}
                    searchAgeMin={searchAgeMin}
                    setSearchAgeMin={setSearchAgeMin}
                    searchAgeMax={searchAgeMax}
                    setSearchAgeMax={setSearchAgeMax}
                    selectedPrefecture={selectedPrefecture}
                    setSelectedPrefecture={setSelectedPrefecture}
                    setIsSearchIconClicked={setIsSearchIconClicked}
                    loadMoreData={loadMoreData}
                    styles={UserSearchStyles}
                    updateDisplayedSearchConditions={updateDisplayedSearchConditions}
                    handleDetailSearch={handleDetailSearch}
                />
            )}
            <div className={styles.SearchResultWrapper} >
                {/* 検索結果 */}
                {searchResults.length === 0 &&
                    <div className={styles.SearchResultMessage}>
                        該当するユーザーは見つかりませんでした。
                    </div>
                }
                <div className={styles.SearchResultContainer}>
                    {searchResults.length > 0 && (
                        <>
                            <div className={styles.SearchResultTitleContainer}>
                                <div className={styles.SearchResultTitle}>
                                    検索結果
                                </div>
                            </div>
                            <ul className={styles.SearchResultList}>
                                <li className={styles.SearchResultListHead}>
                                    <div className={styles.SearchResultListHeadRow}>
                                        <div className={`${styles.UserName} ${styles.SearchResultListHeadItem}`}>ユーザー名</div>
                                        <div className={`${styles.UserEmail} ${styles.SearchResultListHeadItem}`}>メールアドレス</div>
                                        <div className={`${styles.UserAge} ${styles.SearchResultListHeadItem}`}>年齢</div>
                                        <div className={`${styles.UserPrefecture} ${styles.SearchResultListHeadItem}`}>都道府県</div>
                                        <div className={`${styles.UserMemberType} ${styles.SearchResultListHeadItem}`}>会員区分</div>
                                        <div className={`${styles.UserRegistrationDate} ${styles.SearchResultListHeadItem}`}>登録日</div>
                                    </div>
                                </li>
                                <li className={styles.ScrollableList} onScroll={handleScroll} ref={searchResultRef}>
                                    {searchResults.map((result, index) => (
                                        <div key={`${result.userId}-${index}`} className={styles.SearchResultListBodyRow} onClick={() => { handleDetail(result); }}>
                                            <div className={`${styles.UserName} ${styles.SearchResultListBodyItem}`}>
                                                {result.lastName} {result.firstName}
                                            </div>
                                            <div className={`${styles.UserEmail} ${styles.SearchResultListBodyItem}`}>
                                                {result.mail}
                                            </div>
                                            <div className={`${styles.UserAge} ${styles.SearchResultListBodyItem}`}>
                                                {calculateAge(result.birthDate)}
                                            </div>
                                            <div className={`${styles.UserPrefecture} ${styles.SearchResultListBodyItem}`}>
                                                {result.prefecture}
                                            </div>
                                            <div className={`${styles.UserMemberType} ${styles.SearchResultListBodyItem}`}>
                                                {result.memberTypeName}
                                            </div>
                                            <div className={`${styles.UserRegistrationDate} ${styles.SearchResultListBodyItem}`}>
                                                {formatDate(result.createDate)}
                                            </div>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </>
                    )}
                </div>
                {/* ユーザー情報 */}
                <div
                    ref={userPageRef}
                    className={`${styles.UserPageContainer} ${openUserPage ? styles.open : styles.close}`}
                >
                    <UserPage
                        data={selectedUserDetail}
                        selectedChannel={selectedChannel}
                        setSelectedChannel={setSelectedChannel}
                        setOpenUserPage={setOpenUserPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserDetail;
