import React from 'react';
import commonStyles from '../css/Common.module.css';
import { RiGroupLine } from 'react-icons/ri';

const TotalUser = ({ userCount }) => {
    return (
        <>
            <div className={commonStyles.HeaderBox}>
                <div className={commonStyles.ContentHeader}>
                    <div className={commonStyles.ContentTitleLeft}>
                        <div className={commonStyles.TitleText}>
                            総ユーザー数
                        </div>
                    </div>
                    <div className={commonStyles.ContentTitleRight}>
                        <div className={commonStyles.ContentIcon}>
                            <RiGroupLine size={20} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={commonStyles.ValueBox}>
                <div className={commonStyles.ContentValue}>
                    {userCount}
                </div>
            </div>
        </>
    );
};

export default TotalUser;
