export const insertInviteCode = async (navigate, memberTypeCode, issueCount, expiryDate) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const inviteCodeUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_INSERT_INVITE_CODE}`;

        const response = await fetch(inviteCodeUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                memberTypeCode: memberTypeCode,
                issueCount: issueCount,
                expiryDate: expiryDate
            }),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '招待コードの挿入に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：招待コードの挿入', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '招待コードの挿入に失敗しました' } });
    }
};

export const selectInviteCodeHistory = async (navigate) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const inviteCodeUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_SELECT_INVITE_CODE_HISTORY}`;


        const response = await fetch(inviteCodeUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'

        });

        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '招待コード履歴の取得に失敗しました' } });
        }

    } catch (error) {
        console.error('APIエラー：招待コード履歴の取得', error);

        navigate('/error', { state: { statusCode: '', errorMessage: '招待コード履歴の取得に失敗しました' } });
    }
};

export const deleteInviteCode = async (navigate, inviteCode) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const inviteCodeUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_DELETE_INVITE_CODE}?inviteCode=${inviteCode}`;

        const response = await fetch(inviteCodeUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ inviteCode }),
            credentials: 'include'
        });

        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '招待コードの削除に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：招待コードの削除', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '招待コードの削除に失敗しました' } });
    }
};
