import React from 'react';
import styles from '../css/ConfirmationModal.module.css';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, user }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.ModalOverlay}>
            <div className={styles.ModalContent}>
                <h4 className={styles.ModalTitle}>ユーザーを削除しますか？</h4>
                <p className={styles.ModalMessage}>この操作は取り消せません。<br /><b>{user.lastName} {user.firstName}</b>のアカウントが完全に削除されます。</p>
                <div className={styles.ButtonContainer}>
                    <button onClick={onConfirm} className={styles.ConfirmButton}>削除</button>
                    <button onClick={onClose} className={styles.CancelButton}>キャンセル</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal; 