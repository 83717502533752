export const fetchDashboardData = async (navigate) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const userCountUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_USER_COUNT}`;
        const userRegistrationStatusUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_USER_REGISTRATION_STATUS}`;
        const recentAiQuestionsUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_RECENT_AI_QUESTIONS}`;
        const totalAiQuestionsUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_TOTAL_AI_QUESTIONS}`;

        const [userCountResponse, userRegistrationStatusResponse, recentAiQuestionsResponse, totalAiQuestionsResponse] = await Promise.all([
            fetch(userCountUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            }),
            fetch(userRegistrationStatusUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            }),
            fetch(recentAiQuestionsUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            }),
            fetch(totalAiQuestionsUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            })
        ]);


        if (userCountResponse.ok && userRegistrationStatusResponse.ok && recentAiQuestionsResponse.ok && totalAiQuestionsResponse.ok) {
            const userCountData = await userCountResponse.json();
            const userRegistrationStatusData = await userRegistrationStatusResponse.json();
            const recentAiQuestionsData = await recentAiQuestionsResponse.json();
            const totalAiQuestionsData = await totalAiQuestionsResponse.json();

            return {
                success: true,
                userCount: userCountData,
                recentUsers: userRegistrationStatusData,
                recentQuestions: recentAiQuestionsData,
                totalQuestions: totalAiQuestionsData
            };
        } else {
            const errorMessages = [];
            if (!userCountResponse.ok) {
                if(userCountResponse.status === 401){
                    navigate('/login');
                }else{
                    errorMessages.push(`User Count Error: ${userCountResponse.statusText}`);
                }
            }
            if (!userRegistrationStatusResponse.ok) {
                if(userRegistrationStatusResponse.status === 401){
                    navigate('/login');
                }else{
                    const userRegistrationStatusError = await userRegistrationStatusResponse.json();
                    errorMessages.push(`User Registration Status Error: ${userRegistrationStatusError.error}`);
                }
            }
            if (!recentAiQuestionsResponse.ok) {
                if(recentAiQuestionsResponse.status === 401){
                    navigate('/login');
                }else{
                    const recentAiQuestionsError = await recentAiQuestionsResponse.json();
                    errorMessages.push(`Recent AI Questions Error: ${recentAiQuestionsError.error}`);
                }
            }
            if (!totalAiQuestionsResponse.ok) {
                if(totalAiQuestionsResponse.status === 401){
                    navigate('/login');
                }else{
                    const totalAiQuestionsError = await totalAiQuestionsResponse.json();
                    errorMessages.push(`Total AI Questions Error: ${totalAiQuestionsError.error}`);
                }
            }
            return { success: false, message: errorMessages.join('; ') };
        }
    } catch (error) {
        console.error('APIエラー:', error);
        navigate('/error',{state:{statusCode:500,errorMessage:'ダッシュボードデータ取得に失敗しました'}});
    }
};