import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import styles from '../css/EditSearchUser.module.css';
import formatDate from '../utils/DateUtils';
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { editSearchUser, updateUser, deleteUser } from '../api/editUser';
import ConfirmationModal from './ConfirmationModal';
import NotificationModal from './NotificationModal'; // Import the notification modal

const EditSearchUser = ({ setSearchResultMessage, searchResultMessage, setOpenEditor, setSelectedUserDetail, selectedUser, setSelectedUser }) => {
    const navigate = useNavigate();
    const { memberTypeList } = useOutletContext();
    // 「全て」を含むメンバータイプのリスト
    const [modifiedMemberTypeList, setModifiedMemberTypeList] = useState([]);
    // 選択したメンバータイプのコード
    const [selectedMemberTypeCode, setSelectedMemberTypeCode] = useState('00');
    // 検索結果
    const [searchResults, setSearchResults] = useState([]);
    // 検索ワード
    const [searchWord, setSearchWord] = useState('');
    // ページ番号
    const [page, setPage] = useState(1);
    // ローディング中か否か
    const [loading, setLoading] = useState(false);
    // メンバータイプのドロップダウン
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // 選択したメンバータイプ
    const [choicedMemberType, setChoicedMemberType] = useState({
        choicedMemberTypeCode: null,
        choicedMemberTypeName: '一括編集'
    });
    // モーダルを開くか否か
    const [isModalOpen, setIsModalOpen] = useState(false);
    // 削除するユーザー
    const [userToDelete, setUserToDelete] = useState(null);
    // 通知モーダル
    const [isNotificationOpen, setIsNotificationOpen] = useState(false); // State for notification
    const [notificationMessage, setNotificationMessage] = useState(''); // State for notification message
    // メンバータイプのドロップダウンのリファレンス
    const dropdownRef = useRef(null);
    /*----------------------------------- 個別編集用 -----------------------------------*/
    const handleEditUser = (result) => {
        setOpenEditor(true);
        setSelectedUserDetail(result);
    };

    /*----------------------------------- 一括編集用 -----------------------------------*/

    // 選択したユーザーをセット
    const handleSelectUser = (result) => {
        setSelectedUser((prev) => {
            return prev.includes(result) ? prev.filter((user) => user !== result) : [...prev, result];
        });
    };

    // 全選択
    const handleSelectAll = () => {
        setSelectedUser((prev) => {
            if (prev.length === searchResults.length) {
                return [];
            } else {
                return searchResults;
            }
        });
    };

    // メンバータイプを選択
    const handleSelectType = (type) => {
        setChoicedMemberType({
            choicedMemberTypeCode: type.memberTypeCode,
            choicedMemberTypeName: type.memberTypeName
        });
        setIsDropdownOpen(false);
    };

    // 選択されたユーザーへ変更を適用
    const handleBulkEdit = async () => {
        const modifiedSelectedUser = selectedUser.map((user) => {
            user.memberTypeCode = choicedMemberType.choicedMemberTypeCode;
            return user;
        });

        const response = await updateUser(navigate, modifiedSelectedUser);
        if (response) {
            setNotificationMessage(response.message); // Set the notification message
            setIsNotificationOpen(true); // Open the notification

            if (response.success) {
                localStorage.setItem('notificationMessage', response.message); // Store notification message in localStorage
                window.location.reload();
            }
        }
    };

    /*----------------------------------- 共通 -----------------------------------*/

    // スクロール
    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight * 1.3) {
            loadMoreData();
        }
    };

    // 追加データの取得
    const loadMoreData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const nextPage = page + 1;
            const results = await editSearchUser(navigate, searchWord, selectedMemberTypeCode, nextPage);

            if (results.length > 0) {
                setSearchResults((prevResults) => [...prevResults, ...results]);
                setPage(nextPage);
            }
        } catch (error) {
            console.error('APIエラー：追加データの取得', error);
        } finally {
            setLoading(false);
        }
    };

    // 検索ワードの変更
    const handleInputChange = (e) => {
        setSearchWord(e.target.value);
    };

    // メンバータイプの選択
    const handleSelectChange = (e) => {
        setSelectedMemberTypeCode(e.target.value);
    };

    // メンバータイプのドロップダウンを開く
    const handleDropdownToggle = (e) => {
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    };

    // メンバータイプのドロップダウンの外側をクリックした場合
    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    };

    // 削除するユーザーをセット
    const handleDeleteUser = (result) => {
        setUserToDelete(result);
        setIsModalOpen(true);
    };

    // 削除を実行する
    const confirmDeleteUser = async (user) => {
        const response = await deleteUser(navigate, user);
        if (response) {
            if (response.success) {
                setNotificationMessage(response.message); // 通知メッセージをセット
                setIsNotificationOpen(true); // 通知モーダルを開く
                setIsModalOpen(false);
                localStorage.setItem('notificationMessage', response.message); // 通知メッセージをローカルストレージに保存
                window.location.reload();
            } else {
                setNotificationMessage(response.message); // 通知メッセージをセット
                setIsNotificationOpen(true); // 通知モーダルを開く
            }
        }
    };

    /*----------------------------------- 初期ロード -----------------------------------*/

    // 初期ロード
    useEffect(() => {
        const modifiedMemberType = [
            { memberTypeCode: '00', memberTypeName: '全て' },
            ...memberTypeList
        ];
        setModifiedMemberTypeList(modifiedMemberType);
        setSelectedMemberTypeCode(modifiedMemberType[0].memberTypeCode);

        // ローカルストレージから通知メッセージを取得
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage'); // 使用後に削除
        }
    }, [setSearchResultMessage, memberTypeList]); // 初期ロード時にのみ実行

    // メンバータイプのドロップダウンの外側をクリックした場合
    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isDropdownOpen]);

    /*----------------------------------- 値に変更があった場合レンダリング -----------------------------------*/

    // 検索処理
    const handleSearch = useCallback(async (event) => {
        if (event) event.preventDefault(); // デフォルトのフォーム送信をキャンセル
        setPage(1);
        try {
            const results = await editSearchUser(navigate, searchWord, selectedMemberTypeCode, 1);
            if (results) {
                setSearchResults(results);
                setSearchResultMessage(results.length === 0 ? '該当するユーザーは見つかりませんでした。' : '');
            }
        } catch (error) {
            console.error('APIエラー：ユーザー検索', error);
        }
    }, [navigate, searchWord, selectedMemberTypeCode, setSearchResultMessage]);

    // 検索ワードまたはメンバータイプが変更された場合
    useEffect(() => {
        if (searchWord || selectedMemberTypeCode) {
            handleSearch();
        }
    }, [searchWord, selectedMemberTypeCode, handleSearch]);

    return (
        <div className={styles.SearchContainer}>
            <form className={styles.SearchForm} onSubmit={handleSearch}>
                <input
                    type="text"
                    placeholder="ユーザー名またはメールアドレスで検索..."
                    onChange={handleInputChange}
                    className={styles.SearchInput}
                />
                <select onChange={handleSelectChange} className={styles.SearchSelect}>
                    {modifiedMemberTypeList.map((type) => (
                        <option key={type.memberTypeCode} value={type.memberTypeCode}>
                            {type.memberTypeName}
                        </option>
                    ))}
                </select>
            </form>
            <div className={styles.BulkEditContainer}>
                <button
                    className={styles.BulkSelectAllButton}
                    onClick={handleSelectAll}
                >
                    {selectedUser && selectedUser.length === searchResults.length && searchResults.length > 0 ? '全選択解除' : '全選択'}
                </button>
                <div className={styles.DropdownContainer}>
                    <button onClick={handleDropdownToggle} className={styles.BulkSelectMemberType}>
                        {choicedMemberType.choicedMemberTypeName} ▾
                    </button>

                    {isDropdownOpen && (
                        <div className={styles.DropdownMenu} ref={dropdownRef}>
                            {memberTypeList.map((type) => (
                                <div
                                    key={type.memberTypeCode}
                                    className={styles.DropdownMenuItem}
                                    onClick={() => handleSelectType(type)}
                                >
                                    {type.memberTypeName}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <button className={`${styles.BulkApplyButton} 
                                    ${selectedUser.length === 0 || choicedMemberType.choicedMemberTypeName === '一括編集' ? styles.DisabledButton : ''}`}
                    disabled={selectedUser.length === 0 || choicedMemberType.choicedMemberTypeName === '一括編集'}
                    onClick={handleBulkEdit}
                >
                    適用
                </button>
            </div>
            {searchResultMessage &&
                <div className={styles.SearchResultMessage}>
                    {searchResultMessage}
                </div>
            }
            <div className={styles.SearchResultContainer}>
                {searchResults.length > 0 && (
                    <table className={styles.SearchResultTable}>
                        <thead className={styles.SearchResultTableHead}>
                            <tr className={styles.SearchResultTableHeadRow}>
                                <th className={`${styles.SearchResultTableHeadItem} ${styles.CheckIcon}`}>
                                    選択
                                </th>
                                <th className={`${styles.SearchResultTableHeadItem} ${styles.UserName}`}>ユーザー名</th>
                                <th className={`${styles.SearchResultTableHeadItem} ${styles.Mail}`}>メールアドレス</th>
                                <th className={`${styles.SearchResultTableHeadItem} ${styles.MemberType}`}>会員区分</th>
                                <th className={`${styles.SearchResultTableHeadItem} ${styles.CreateDate}`}>登録日時</th>
                                <th className={`${styles.SearchResultTableHeadItem} ${styles.Edit}`}>個別編集</th>
                            </tr>
                        </thead>
                        <tbody className={styles.ScrolableList} onScroll={handleScroll}>
                            {searchResults.map((result, index) => (
                                <tr
                                    key={`${result.userId}-${result.lastName}-${result.firstName}-${result.mail}-${index}`}
                                    className={styles.SearchResultTableBodyRow}
                                >
                                    <td className={`${styles.SearchResultTableBodyItem} ${styles.CheckIcon}`}>
                                        {selectedUser.includes(result) ? <MdCheckBox onClick={() => handleSelectUser(result)} className={styles.Icon} /> : <MdCheckBoxOutlineBlank onClick={() => handleSelectUser(result)} className={styles.Icon} />}
                                    </td>
                                    <td className={`${styles.SearchResultTableBodyItem} ${styles.UserName}`}>{result.lastName} {result.firstName}</td>
                                    <td className={`${styles.SearchResultTableBodyItem} ${styles.Mail}`}>{result.mail}</td>
                                    <td className={`${styles.SearchResultTableBodyItem} ${styles.MemberType}`}>{result.memberTypeName}</td>
                                    <td className={`${styles.SearchResultTableBodyItem} ${styles.CreateDate}`}>{formatDate(result.createDate)}</td>
                                    <td className={`${styles.SearchResultTableBodyItem} ${styles.Edit}`}>
                                        <button className={styles.EditButton} onClick={() => handleEditUser(result)}>
                                            <FaRegEdit className={styles.ButtonIcon} />
                                        </button>
                                        <button className={styles.DeleteButton} onClick={() => handleDeleteUser(result)}>
                                            <FaTrash className={styles.ButtonIcon} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={() => confirmDeleteUser(userToDelete)}
                user={userToDelete}
            />
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
            />
        </div>
    );
};

export default EditSearchUser;
