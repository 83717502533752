import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import AdminPanel from './components/AdminPanel';
import Dashboard from './components/Dashboard';
import UserDetail from './components/UserDetail';
import RecentQuestionPage from './components/RecentQuestionPage';
import InviteCodeManagement from './components/InviteCodeManagement';
import EditUser from './components/EditUser';
import ErrorPage from './components/ErrorPage';
import Statistics from './components/Statistics';
function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/adminPanel" element={<AdminPanel />}>
          <Route index element={<Dashboard />} />
          <Route path="user-detail" element={<UserDetail />} />
          <Route path="invite-codes" element={<InviteCodeManagement />} />
          <Route path="ai-questions" element={<RecentQuestionPage />} />
          <Route path="edit-user" element={<EditUser />} />
          <Route path="statistics" element={<Statistics />} />
        </Route>
        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/error" state={{ errorCode: 404, errorMessage: 'ページが見つかりませんでした' }} />} />
      </Routes>
    </div>
  );
};

export default App;
