export const getPeriodList = async (navigate, channelId) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const periodListUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_PERIOD_LIST}?channelId=${channelId}`;

        const response = await fetch(periodListUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
        });

        if (response.ok) {
            return await response.json();
        }else if(response.status === 401){
            navigate('/login');
        }else{
            navigate('/error',{state:{statusCode:response.status,errorMessage:'期間リストの取得に失敗しました'}});
        }
    } catch (error) {
        console.error('APIエラー：期間リストの取得', error);
        throw error;
    }
};

export const fetchChatRoom = async (navigate, userId, periodCode, partId, type) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const chatRoomUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_CHAT_ROOM}?userId=${userId}&periodCode=${periodCode}&partId=${partId}&type=${type}`;

        const response = await fetch(chatRoomUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });

        if (response.ok) {
            return await response.json();
        }else if(response.status === 401){
            navigate('/login');
        }else{
            navigate('/error',{state:{statusCode:response.status,errorMessage:'チャットルームの取得に失敗しました'}});
        }
    } catch (error) {
        console.error('APIエラー：チャットルームの取得', error);
        throw error;
    }
};

export const fetchChatHistoryData = async (navigate, userId, conversationId) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const chatHistoryUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_CHAT_HISTORY}?userId=${userId}&conversationId=${conversationId}`;

        const response = await fetch(chatHistoryUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });

        if (response.ok) {
            return await response.json();
        }else if(response.status === 401){
            navigate('/login');
        }else{
            navigate('/error',{state:{statusCode:response.status,errorMessage:'質問履歴の取得に失敗しました'}});
        }
    } catch (error) {
        console.error('APIエラー：質問履歴の取得', error);
        throw error;
    }
};
