import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from '../css/AdminPanel.module.css';
// import Header from './Header';
import SideMenu from './SideMenu';
import { useState, useEffect, useCallback } from 'react';

const AdminPanel = () => {
    const navigate = useNavigate();
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [memberTypeList, setMemberTypeList] = useState([]);

    const fetchMemberTypes = useCallback(async () => {
        try {
            const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
            const memberTypeUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_MEMBER_TYPE_LIST}`;
            const response = await fetch(memberTypeUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                setMemberTypeList(data);
            } else {
                navigate('/error', { state: { statusCode: response.status, errorMessage: '会員区分の取得に失敗しました' } });
            }
        } catch (error) {
            navigate('/error', { state: { statusCode: 500, errorMessage: '認証情報が削除されました。' } });
        }
    }, [navigate]);
    useEffect(() => {
        fetchMemberTypes();
    }, [fetchMemberTypes]);


    return (
        <div className={styles.AdminPanelContainer}>
            <div className={styles.sideMenu}>
                <SideMenu selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
            </div>
            <div className={styles.MainPanel}>
                <main className={styles.mainContents}>
                    <Outlet context={{ memberTypeList, setMemberTypeList }} />
                </main>
            </div>
        </div>
    );
};

export default AdminPanel;