import React from 'react';
import styles from '../css/Statistics.module.css';

const StatisticsOverview = () => {
    return (
        <div className={styles.WholeItemContainer}>
            <div className={styles.ItemContainer}>
                <label className={styles.ItemTitle}>
                    総ユーザー数
                </label>
                <div className={styles.ItemValue}>
                    100
                </div>
            </div>
            <div className={styles.ItemContainer}>
                <label className={styles.ItemTitle}>
                    総質問数
                </label>
                <div className={styles.ItemValue}>
                    100
                </div>
            </div>
            <div className={styles.ItemContainer}>
                <label className={styles.ItemTitle}>
                    総回答数
                </label>
                <div className={styles.ItemValue}>
                    100
                </div>
            </div>
            <div className={styles.ItemContainer}>
                <label className={styles.ItemTitle}>
                    総質問数
                </label>
                <div className={styles.ItemValue}>
                    100
                </div>
            </div>
        </div>
    );
};

export default StatisticsOverview;