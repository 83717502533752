import React from 'react';
import { LuAperture } from "react-icons/lu";
import styles from '../css/TopHeader.module.css';

const TopHeader = () => {

  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <LuAperture className={styles.titleLogo} size={24} />
      </div>
    </header>
  );
};

export default TopHeader;