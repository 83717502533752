import React, { useEffect } from 'react';
import styles from '../css/NotificationModal.module.css';

const NotificationModal = ({ isOpen, message, onClose, type }) => {
    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(onClose, 3000);
            return () => clearTimeout(timer);
        }
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    const modalClass = type === 'error' ? styles.ErrorNotification : styles.SuccessNotification;

    return (
        <div className={`${styles.Notification} ${modalClass}`}>
            <p className={styles.Message}>{message}</p>
            <button onClick={onClose} className={styles.CloseButton}>×</button>
        </div>
    );
};

export default NotificationModal; 