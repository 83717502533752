import { React, useState } from 'react';
import styles from '../css/Login.module.css';
import { issueToken } from '../api/authentication';
import TopHeader from './TopHeader';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const isAuthenticated = await issueToken(mail, password);
            if (isAuthenticated.success) {
                localStorage.setItem('token', isAuthenticated.token)
                navigate('/adminPanel');
            } else {
                setErrorMessage(isAuthenticated.message);
            }
        } catch (error) {
            setErrorMessage('システムエラーが発生しました。システム管理者にお問い合わせください。');
            console.error('Login.error:', error);
        }
    };

    return (
        <div className={styles.loginContainer}>
            <TopHeader />
            <div className={styles.loginModal}>
                <div className={styles.loginContent}>
                    <div className={styles.loginHeader}>
                        <h2>ログイン</h2>
                        <span>アカウントにログインしてください</span>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.formGroup}>
                            <span>メールアドレス</span>
                            <input
                                type="email"
                                id="mail"
                                value={mail}
                                onChange={(e) => setMail(e.target.value)}
                                placeholder="EMAIL"
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <span>パスワード</span>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="PASSWORD"
                                required
                            />
                        </div>
                        <div className={styles.errorContainer}>
                            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
                        </div>
                        <button type="submit" className={styles.loginButton}>ログイン</button>
                    </form>
                    <div className={styles.loginLinks}>
                        <span>パスワードをお忘れですか？</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;