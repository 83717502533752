import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../css/ErrorPage.module.css';
import { FaRegFaceSadTear } from "react-icons/fa6";

const ErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { errorCode, errorMessage } = location.state || { errorCode: '', errorMessage: '' };

  const getErrorMessage = (code) => {
    switch (code) {
      case 404:
        return 'ページが見つかりませんでした';
      case 500:
        return 'サーバーエラーが発生しました';
      default:
        return 'エラーが発生しました';
    }
  };

  const handleGoHome = async () => {
    try {
      navigate('/login');
    } catch (error) {
      navigate('/error', { state: { errorCode: '', errorMessage: '' } });
    }
  };

  return (
    <div className={styles.errorPage}>
      <div className={styles.errorContainer}>
        <div className={styles.errorMessageContainer}>
          <FaRegFaceSadTear size={100} color={'#21a5f1'} />
          <h1>{errorCode}</h1>
          <h2>{errorMessage || getErrorMessage(errorCode)}</h2>
          <p>申し訳ありません、問題が発生しました。しばらくしてからもう一度お試しください。</p>
          <button onClick={handleGoHome}>トップページに戻る</button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;