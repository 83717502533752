export const fetchPartsProgressData = async (navigate, userId, partId) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const partsProgressUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_PARTS_PROGRESS_RATE}`;

        const response = await fetch(partsProgressUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ userId, partId })
        });

        if (response.ok) {
            return await response.json();
        }else if(response.status === 401){
            navigate('/login');
        }else{
            console.error('パート別の進捗率の取得に失敗しました');
            navigate('/error', { state: { errorCode: response.status, errorMessage: 'パート別の進捗率の取得時にエラーが発生しました' } });
        }

    } catch (error) {
        console.error('APIエラー：パート別の進捗率の取得', error);
        throw error;
    }
};

export const fetchSectionsProgressData = async (navigate, userId, partId) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const sectionsProgressUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_SECTIONS_PROGRESS_RATE}?userId=${userId}&partId=${partId}`;

        const response = await fetch(sectionsProgressUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
        });

        if (response.ok) {
            return await response.json();
        }else if(response.status === 401){
            navigate('/login');
        }else{
            console.error('セクション別の進捗率の取得に失敗しました');
            navigate('/error', { state: { errorCode: response.status, errorMessage: 'セクション別の進捗率の取得時にエラーが発生しました' } });
        }

    } catch (error) {
        console.error('APIエラー：セクション別の進捗率の取得', error);
        throw error;
    }
};



