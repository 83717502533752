export const fetchSearchResults = async (
    navigate,
    searchName,
    searchEmail,
    searchAgeMin,
    searchAgeMax,
    selectedPrefecture,
    selectedMemberType,
    page) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const searchResultsUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_USER_LIST}`;

        const response = await fetch(searchResultsUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                searchName: searchName,
                searchEmail: searchEmail,
                searchAgeMin: searchAgeMin,
                searchAgeMax: searchAgeMax,
                selectedPrefecture: selectedPrefecture,
                memberType: selectedMemberType,
                page: page
            }),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        }else if(response.status === 401){
            navigate('/login');
        }else{
            navigate('/error',{state:{statusCode:response.status,errorMessage:'検索結果の取得に失敗しました'}});
        }
    } catch (error) {
        console.error('APIエラー：検索結果の取得', error);
        navigate('/error',{state:{statusCode:'',errorMessage:'検索結果の取得に失敗しました'}});
    }
};