import React from 'react';
import styles from '../css/Dashboard.module.css';
import commonStyles from '../css/Common.module.css';
import { RiHistoryFill } from 'react-icons/ri';
import formatDate from '../utils/DateUtils';

const RecentRegistrationCard = ({ recentUsers, listRef, handleResizeStart }) => {
    return (
        <>
            <div className={commonStyles.HeaderBox}>
                <div className={commonStyles.ContentHeader}>
                    <div className={commonStyles.ContentTitleLeft}>
                        <div className={commonStyles.TitleText}>
                            最近のユーザー登録
                        </div>
                        <div className={commonStyles.subTitle}>
                            過去7日間の新規ユーザー
                        </div>
                    </div>
                    <div className={commonStyles.ContentTitleRight}>
                        <div className={commonStyles.ContentIcon}>
                            <RiHistoryFill size={20} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={commonStyles.ValueBox}>
                {recentUsers?.length > 0 ? (
                    <div className={styles.ResizableContainer}>
                        <ul ref={listRef} className={styles.ScrollableList}>
                            {recentUsers.map(user => (
                                <li key={user.id} className={styles.UserItem}>
                                    <div className={styles.LeftColumn}>
                                        <div className={styles.UserName}>
                                            {user.lastName} {user.firstName}
                                        </div>
                                        <div className={styles.UserEmail}>
                                            {user.mail}
                                        </div>
                                    </div>
                                    <div className={styles.RightColumn}>
                                        <div className={styles.UserDate}>
                                            {formatDate(user.createDate)}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className={styles.ResizeHandle} onMouseDown={handleResizeStart} />
                    </div>
                ) : (
                    '最近のユーザー登録はありません'
                )}
            </div>
        </>
    );
};
export default RecentRegistrationCard;