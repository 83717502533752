import React from "react";
import styles from "../css/Pagenation.module.css";

export default function Pagination({ currentPage, totalPages, onPageChange }) {
    const getPageNumbers = () => {
        const pages = [];

        // 最初の2ページを追加
        if (totalPages >= 1) pages.push(1);
        if (totalPages >= 2 && !pages.includes(2)) pages.push(2);

        // 現在のページの前後を追加
        if (currentPage > 3) pages.push("..."); // 省略記号
        for (let i = Math.max(3, currentPage - 1); i <= Math.min(totalPages - 2, currentPage + 1); i++) {
            pages.push(i);
        }
        if (currentPage < totalPages - 2) pages.push("..."); // 省略記号

        // 最後の2ページを追加
        if (totalPages > 1 && !pages.includes(totalPages - 1)) pages.push(totalPages - 1);
        if (!pages.includes(totalPages)) pages.push(totalPages);
        return pages;
    };

    const pageNumbers = getPageNumbers();

    return (
        <div className={styles.pagination}>
            <button className={`${styles.prevButton} ${currentPage === 1 ? styles.unClickable : ""}`} onClick={() => onPageChange(currentPage - 1)}>←  前へ</button>
            <div className={styles.pageNumbers}>
                {pageNumbers.map((pageNumber, index) => (
                    <button
                        key={index}
                        onClick={() => typeof pageNumber === "number" && onPageChange(pageNumber)}
                        className={`${styles.paginationButton} 
                                    ${currentPage === pageNumber ? styles.currentPage : ""}
                                    ${pageNumber === "..." ? styles.disabledButton : ""}`}
                        disabled={pageNumber === "..."}
                    >
                        {pageNumber}
                    </button>
                ))}
            </div>
            <button className={`${styles.nextButton} ${currentPage === totalPages ? styles.unClickable : ""}`} onClick={() => onPageChange(currentPage + 1)}>次へ →</button>
        </div>
    );
}
