import React from 'react';
import styles from '../css/Statistics.module.css';

const StatisticsCategory = () => {
    return (
        <div className={styles.ChartContainer}>
            カテゴリー
        </div>
    );
};

export default StatisticsCategory;