export const handleMouseMove = (e, listRef, isResizing) => {
    if (!isResizing.current) return;

    const newHeight = e.clientY - listRef.current.getBoundingClientRect().top;
    if (newHeight >= 100 && newHeight <= 500) {
        listRef.current.style.height = `${newHeight}px`;
    }
};

export const handleResizeStart = (isResizing) => {
    isResizing.current = true;
};

export const handleMouseUp = (isResizing) => {
    isResizing.current = false;
};

export const addResizeEventListeners = (handleMouseMove, handleMouseUp) => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
};

export const removeResizeEventListeners = (handleMouseMove, handleMouseUp) => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
};
