import React from 'react';
import commonStyles from '../css/Common.module.css';
import { RiQuestionnaireFill } from 'react-icons/ri';

const TotalQuestion = ({ totalQuestions }) => {
    return (
        <div className={commonStyles.ValueBox}>
            <div className={commonStyles.HeaderBox}>
                <div className={commonStyles.ContentHeader}>
                    <div className={commonStyles.ContentTitleLeft}>
                        <div className={commonStyles.TitleText}>
                            総AI質問数
                        </div>
                    </div>
                    <div className={commonStyles.ContentTitleRight}>
                        <div className={commonStyles.ContentIcon}>
                            <RiQuestionnaireFill size={20} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={commonStyles.ValueBox}>
                <div className={commonStyles.ContentValue}>
                    {totalQuestions}
                </div>
            </div>
        </div>
    );
};
export default TotalQuestion;