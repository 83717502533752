import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import styles from '../css/InviteCodeManagement.module.css';
import CommonStyles from '../css/Common.module.css';
import { FaRegCopy } from 'react-icons/fa';
import { IoTrashOutline } from 'react-icons/io5';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import NotificationModal from './NotificationModal';
import { insertInviteCode, selectInviteCodeHistory, deleteInviteCode } from '../api/inviteCode';

const InviteCodeManagement = () => {
    const navigate = useNavigate();
    const { memberTypeList } = useOutletContext();
    const [selectedMemberTypeCode, setSelectedMemberTypeCode] = useState('01');
    const [expiryDate, setExpiryDate] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [issueCount, setIssueCount] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [inviteCodeHistory, setInviteCodeHistory] = useState([]);
    const [showFullCode, setShowFullCode] = useState(null);

    const handleGenerateCode = async (e) => {
        e.preventDefault();
        if (!issueCount && !expiryDate) {
            setErrorMessage('発行件数と有効期限を入力してください。');
            return;
        } else if (!issueCount) {
            setErrorMessage('発行件数を入力してください。');
            return;
        } else if (!expiryDate) {
            setErrorMessage('有効期限を入力してください。');
            return;
        }
        const response = await insertInviteCode(navigate, selectedMemberTypeCode, issueCount, expiryDate);
        if (response) {
            setNotificationMessage(response.message);
            setNotificationType(response.success ? 'success' : 'error');
            setIsNotificationOpen(true);

            // 成功した場合はローカルストレージに通知メッセージを保存し、リロード
            if (response.success) {
                localStorage.setItem('notificationMessage', response.message);
                window.location.reload();
            }
        }
    };

    const handleCopyToClipboard = (inviteCode) => {
        navigator.clipboard.writeText(inviteCode)
            .then(() => {
                setNotificationMessage('招待コードをコピーしました。');
                setNotificationType('success');
                setIsNotificationOpen(true);
            })
            .catch(err => {
                console.error('コピーに失敗しました: ', err);
            });
    };

    // const closeModal = () => {
    //     setIsModalOpen(false);
    // };

    useEffect(() => {
        // ローカルストレージに通知メッセージがある場合は表示
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage');
        }
    }, []);

    useEffect(() => {
        if (memberTypeList?.length > 0) {
            setSelectedMemberTypeCode(memberTypeList[0]?.memberTypeCode);
        }
    }, [memberTypeList]);

    useEffect(() => {
        const getInviteCodeHistory = async () => {
            const response = await selectInviteCodeHistory(navigate);
            if (response) {
                setInviteCodeHistory(response);
            }
        };
        getInviteCodeHistory();
    }, [navigate]);

    const handleDeleteCode = async (inviteCode) => {
        try {
            const response = await deleteInviteCode(navigate, inviteCode);

            if (response > 0) {
                setNotificationMessage('招待コードを削除しました。');
                setNotificationType('success');
                setIsNotificationOpen(true);
                window.location.reload();
            } else {
                throw new Error('招待コードの削除に失敗しました。');
            }
        } catch (error) {
            setNotificationMessage(error.message || '招待コードの削除に失敗しました。');
            setNotificationType('error');
            setIsNotificationOpen(true);
        }
    };

    return (
        <>
            <div className={CommonStyles.WholeContainer}>
                <div className={CommonStyles.TitleContainer}>
                    <div className={CommonStyles.TitleText}>
                        招待コード
                    </div>
                    <div className={CommonStyles.subTitle}>
                        招待コードを管理します。
                    </div>
                </div>
                <div className={styles.ContentContainer}>
                    <div className={styles.GenerateCodeCard}>
                        <div className={styles.Contents}>
                            <div className={styles.ContentHeader}>
                                <div className={styles.ContentTitle}>
                                    招待コード生成
                                </div>
                                <div className={styles.ContentSubTitle}>
                                    招待コードを生成します。
                                </div>
                            </div>
                            <div className={styles.ContentBody}>
                                <form className={styles.GenerateCodeContainer} onSubmit={handleGenerateCode}>
                                    <div className={styles.Wrapper}>
                                        <span className={styles.Label}>会員区分</span>
                                        <select
                                            className={styles.valueInput}
                                            onChange={(e) => setSelectedMemberTypeCode(e.target.value)}
                                            value={selectedMemberTypeCode}
                                        >
                                            {memberTypeList?.length > 0 ? (
                                                memberTypeList?.map((memberType) => (
                                                    <option key={memberType?.memberTypeCode} value={memberType?.memberTypeCode}>
                                                        {memberType?.memberTypeName}
                                                    </option>
                                                ))
                                            ) : (
                                                <option>Loading...</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className={styles.Wrapper}>
                                        <span className={styles.Label}>発行件数</span>
                                        <input
                                            type="number"
                                            className={styles.valueInput}
                                            min="1"
                                            onChange={(e) => setIssueCount(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.Wrapper}>
                                        <span className={styles.Label}>有効期限</span>
                                        <input
                                            type="date"
                                            className={styles.valueInput}
                                            onChange={(e) => setExpiryDate(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.Wrapper}>
                                        <button
                                            className={styles.GenerateCodeButton}
                                            type="submit"
                                        >
                                            生成
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={styles.InviteCodeCard}>
                        <div className={styles.Contents}>
                            <div className={styles.ContentHeader}>
                                <div className={styles.ContentTitle}>
                                    招待コード一覧
                                </div>
                                <div className={styles.ContentSubTitle}>
                                    招待コードを一覧表示します。
                                </div>
                            </div>
                            <div className={styles.ContentBody}>
                                <div className={styles.inviteCodeContents}>
                                    <div className={styles.inviteCodeHeader}>
                                        <span>招待コード</span>
                                        <span>会員区分</span>
                                        <span>生成日時</span>
                                        <span>有効期限</span>
                                        <span>使用状態</span>
                                        <span>アクション</span>
                                    </div>
                                    <div className={styles.inviteCodeContainer}>
                                        {inviteCodeHistory?.length > 0 && inviteCodeHistory?.map((history, index) => (
                                            <div
                                                key={`${index}-${history?.inviteCode}`}
                                                className={`${styles.inviteCodeRow}
                                                            ${history?.status === '0' && history?.expiryDate < new Date().toISOString().split('T')[0] ? styles.expiredRow : ''}`}
                                            >
                                                <span>
                                                    {showFullCode === history.inviteCode ? (
                                                        <>
                                                            {history.inviteCode}
                                                            <button onClick={() => setShowFullCode(null)} className={styles.ViewFullCodeButton}>
                                                                <FiEyeOff />
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {history.inviteCode.slice(0, 14)}...
                                                            <button onClick={() => setShowFullCode(history.inviteCode)} className={styles.ViewFullCodeButton}>
                                                                <FiEye />
                                                            </button>
                                                        </>
                                                    )}
                                                </span>
                                                <span>
                                                    {memberTypeList?.find(memberType => memberType?.memberTypeCode === history?.memberTypeCode)?.memberTypeName}
                                                </span>
                                                <span>{history?.createDate}</span>
                                                <span>{history?.expiryDate}</span>
                                                <span className={`${styles.status} ${history?.status === '0' ? styles.notUsedText : styles.usedText}`}>{history?.status === '0' ? '未使用' : '使用済み'}</span>
                                                <span>
                                                    <div className={styles.CopyButtonContainer}>
                                                        <button className={styles.CopyButton} onClick={() => handleCopyToClipboard(history?.inviteCode)} >
                                                            <FaRegCopy />
                                                        </button>
                                                        <button className={styles.DeleteButton} onClick={() => handleDeleteCode(history?.inviteCode)}>
                                                            <IoTrashOutline size={14} />
                                                        </button>
                                                    </div>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {errorMessage && (
                    <NotificationModal
                        isOpen={true}
                        message={errorMessage}
                        onClose={() => setErrorMessage('')}
                        type="error"
                    />
                )}
                <NotificationModal
                    isOpen={isNotificationOpen}
                    message={notificationMessage}
                    onClose={() => setIsNotificationOpen(false)}
                    type={notificationType}
                />
            </div>
        </>
    );
};

export default InviteCodeManagement;
