export function convertArticleStatusJson(data) {
    const groupedData = [];

    if (data) {
        data.forEach(item => {
            const { partId, partTitle, chapterId, chapterTitle, sectionId, sectionTitle, articleId, articleTitle, articleStatus } = item;

            let part = groupedData.find(p => p.partId === partId);
            if (!part) {
                part = {
                    partId,
                    partTitle,
                    chapters: []
                };
                groupedData.push(part);
            }

            let chapter = part.chapters.find(c => c.chapterId === chapterId);
            if (!chapter) {
                chapter = {
                    chapterId,
                    chapterTitle,
                    sections: []
                };
                part.chapters.push(chapter);
            }

            let section = chapter.sections.find(s => s.sectionId === sectionId);
            if (!section) {
                section = {
                    sectionId,
                    sectionTitle,
                    articles: [],
                    sectionStatus: '完了' // 初期値を'完了'に設定
                };
                chapter.sections.push(section);
            }

            section.articles.push({
                articleId,
                articleTitle,
                articleStatus
            });

            // 記事が未完了の場合、セクションの完了ステータスを'未完'に設定
            if (articleStatus !== '完了') {
                section.sectionStatus = '未完';
            }
        });

        // 各チャプターの完了ステータスを再計算
        groupedData.forEach(part => {
            part.chapters.forEach(chapter => {
                // チャプターの完了ステータスを再計算
                const allSectionsCompleted = chapter.sections.every(section => section.sectionStatus === '完了');
                chapter.chapterStatus = allSectionsCompleted ? '完了' : '未完';
            });
        });
    }

    return groupedData;
};
