import React, { useState } from 'react';
import commonStyles from '../css/Common.module.css';
import styles from '../css/Dashboard.module.css';
import { RiHistoryFill, RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';
import formatDate from '../utils/DateUtils';

const RecentQuestionCard = ({ dashboardData, uniqueQuestions }) => {

    const [openQuestions, setOpenQuestions] = useState({});
    const [sameQUser, setSameQUser] = useState({});

    //質問を開く
    const toggleQuestion = (uniqueKey, question) => {

        setOpenQuestions(prevState => ({
            ...prevState,
            [uniqueKey]: !prevState[uniqueKey]
        }));

        const userMap = {};
        dashboardData.recentQuestions
            .filter(q => q.query === question.query)
            .forEach(q => {
                const key = `${q.memberId}`;
                if (userMap[key]) {
                    userMap[key].count += 1;
                } else {
                    userMap[key] = {
                        memberId: q.memberId,
                        lastName: q.lastName,
                        firstName: q.firstName,
                        date: formatDate(q.createdAt),
                        count: 1
                    };
                }
            });

        setSameQUser(prevState => ({
            ...prevState,
            [uniqueKey]: Object.values(userMap)
        }));
    };


    return (
        <>
            <div className={commonStyles.HeaderBox}>
                <div className={commonStyles.ContentHeader}>
                    <div className={commonStyles.ContentTitleLeft}>
                        <div className={commonStyles.TitleText}>
                            最近のAI質問
                        </div>
                        <div className={commonStyles.subTitle}>
                            過去7日間のAI質問（最大50件）
                        </div>
                    </div>
                    <div className={commonStyles.ContentTitleRight}>
                        <div className={commonStyles.ContentIcon}>
                            <RiHistoryFill size={20} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={commonStyles.ValueBox}>
                {dashboardData && dashboardData.recentQuestions && dashboardData.recentQuestions.length > 0 ? (
                    <ul className={styles.ScrollableList}>
                        {uniqueQuestions.slice(0, 50).map(question => (
                            <li className={styles.QuestionContainer} key={`${question.memberId}-${question.query}-${question.createdAt}`} onClick={() => toggleQuestion(`${question.memberId}-${question.query}-${question.createdAt}`, question)}>
                                <div className={styles.QuestionInfo}>
                                    <div key={question.id} className={styles.QuestionText}>
                                        {question.query.length > 20 ? question.query.slice(0, 20) + '...' : question.query}
                                    </div>
                                    <div className={styles.QuestionIcon}>
                                        {openQuestions[`${question.memberId}-${question.query}-${question.createdAt}`] ? <RiArrowDownSLine size={20} /> : <RiArrowRightSLine size={20} />}
                                    </div>
                                </div>
                                {openQuestions[`${question.memberId}-${question.query}-${question.createdAt}`] &&
                                    sameQUser[`${question.memberId}-${question.query}-${question.createdAt}`] &&
                                    sameQUser[`${question.memberId}-${question.query}-${question.createdAt}`].map(user => (
                                        <div
                                            className={styles.SameQUser}
                                            key={user.memberId}
                                        >
                                            <div className={styles.SameQUserName}>
                                                {user.lastName} {user.firstName}
                                            </div>
                                            <div className={styles.SameQUserCount}>
                                                {user.count > 1 ? <span> ({user.count}回)</span> : ''}
                                            </div>
                                            <div className={styles.SameQUserDate}>
                                                {user.date}
                                            </div>
                                        </div>
                                    ))}

                            </li>
                        ))}
                    </ul>
                ) : '最近のAI質問はありません'}
            </div>
        </>
    );
};

export default RecentQuestionCard;