import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/UsersQuestionHistoryCard.module.css';
import Pagination from '../utils/Pagenation';
import { fetchChatHistoryData } from '../api/userQuestionHistory';
import { getPeriodList, fetchChatRoom } from '../api/userQuestionHistory';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { RxCross2 } from 'react-icons/rx';
import ChatResponseMarkdown from './ChatResponseMarkdown';

const UsersQuestionHistoryCard = ({ data, partsList }) => {
    const navigate = useNavigate();
    //--------------------------------------------質問履歴データ--------------------------------------------
    const [isQuestionHistoryLoading, setIsQuestionHistoryLoading] = useState(false);

    //--------------------------------------------ページネーション--------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    //--------------------------------------------期間リストを取得する--------------------------------------------
    const [periodList, setPeriodList] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState('03');

    //--------------------------------------------選択されたパートリストを保持する--------------------------------------------
    const [selectedPart, setSelectedPart] = useState(0);
    const [selectedType, setSelectedType] = useState('2');

    const [customPartsList, setCustomPartsList] = useState([]);

    const [chatRoomList, setChatRoomList] = useState([]);

    const [selectedRoom, setSelectedRoom] = useState(null);

    const [chatHistoryData, setChatHistoryData] = useState([]);

    const closeChat = () => {
        if (selectedRoom) {
            setSelectedRoom('');
        }
    };

    //--------------------------------------------データ取得--------------------------------------------
    const fetchChatRoomList = useCallback(async () => {
        if (data?.id && periodList.length > 0 && selectedPeriod) {
            const chatRoomList = await fetchChatRoom(navigate, data.id, selectedPeriod?.code, selectedPart?.id, selectedType);
            setChatRoomList(chatRoomList);
        }
        setCurrentPage(1);
    }, [navigate, data?.id, periodList, selectedPeriod, selectedPart?.id, selectedType]);

    const fetchChatHistory = useCallback(async (conversationId) => {
        try {
            setIsQuestionHistoryLoading(true);
            const chatHistoryData = await fetchChatHistoryData(navigate, data.id, conversationId);

            if (chatHistoryData.length > 0) {
                setCurrentPage(1);
                setChatHistoryData(chatHistoryData);
            } else {
                setChatHistoryData([]);
            }
        } catch (error) {
            console.error('APIエラー：質問履歴の取得', error);
        } finally {
            setIsQuestionHistoryLoading(false);
        }
    }, [navigate, data.id]);

    //--------------------------------------------チャットルームのかいへい--------------------------------------------
    const toggleRoom = useCallback((roomInfo) => {
        if (selectedRoom && selectedRoom.conversationId === roomInfo.conversationId) {
            setSelectedRoom(null);
        } else {
            setSelectedRoom(roomInfo.conversationId);
            fetchChatHistory(roomInfo.conversationId);
        }
    }, [selectedRoom, setSelectedRoom, fetchChatHistory]);

    //--------------------------------------------ページネーション--------------------------------------------
    const pagenatedChatRooms = useMemo(() => {
        return chatRoomList?.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
        );
    }, [chatRoomList, currentPage, itemsPerPage]);

    //--------------------------------------------ページネーションのページ変更--------------------------------------------
    const handlePageChange = (page) => {
        if (page >= 1 && page <= Math.ceil(chatRoomList?.length / itemsPerPage)) {
            setCurrentPage(page);
        }
    };

    //--------------------------------------------リストクリック--------------------------------------------
    const handlePeriodOptionChange = (event) => {
        const periodCode = event.target.value;
        const period = periodList.find(period => period.code === periodCode);
        setSelectedPeriod(period);
    };

    const handlePartOptionChange = (event) => {
        const partId = parseInt(event.target.value, 10);
        const part = customPartsList.find(part => part.id === partId);
        setSelectedPart(part);
    };

    const handleTypeOptionChange = (type) => {
        setSelectedType(type);
    };

    //--------------------------------------------初期ロード--------------------------------------------

    //検索区分テーブルの取得
    useEffect(() => {
        const fetchPeriodList = async () => {
            //検索区分テーブル（「質問履歴」の場合codeは01、戻り値は検索する期間の長さ）の取得
            const periodList = await getPeriodList(navigate, '01');
            setPeriodList(periodList);
            setSelectedPeriod(periodList[2]); // 初期値「全て」を設定
        };
        fetchPeriodList();
        setCurrentPage(1);
    }, [navigate]);

    //パートリスト
    useEffect(() => {
        //パートリストがある場合は「全ての教材」を追加
        if (partsList.length > 0) {
            const customPartsList = [{ id: 0, partTitle: '全ての教材' }, ...partsList];
            setCustomPartsList(customPartsList);
            setSelectedPart(customPartsList[0]);
        }
    }, [partsList]);

    //チャットルームの取得
    useEffect(() => {
        fetchChatRoomList();
    }, [fetchChatRoomList]);

    return (
        <>
            <div className={styles.chatRoom}>
                <div className={styles.searchContainer}>
                    <select
                        value={selectedPeriod?.code || ''}
                        onChange={handlePeriodOptionChange}
                    >
                        {periodList.map((period) => (
                            <option key={period.code} value={period.code}>
                                {period.codeName}
                            </option>
                        ))}
                    </select>
                    <select
                        value={selectedPart?.id || ''}
                        onChange={handlePartOptionChange}
                    >
                        {customPartsList.map((part) => (
                            <option key={part.id} value={part.id}>
                                {part.partTitle}
                            </option>
                        ))}
                    </select>
                    <select
                        value={selectedType}
                        onChange={(e) => handleTypeOptionChange(e.target.value)}
                    >
                        <option value="2">全ての種別</option>
                        <option value="0">チャットボット</option>
                        <option value="1">コードレビュー</option>
                    </select>
                </div>

                {isQuestionHistoryLoading ? (
                    <div className={styles.noData}>
                        読み込み中...
                    </div>
                ) : (
                    chatRoomList && chatRoomList.length > 0 ? (
                        <>
                            {/* <ConversationListTable
                            pagenatedConversations={pagenatedConversations}
                            toggleRoom={toggleRoom}
                        /> */}
                            <div className={styles.chatRoomContainer}>
                                <div className={styles.chatRoomContents}>
                                    <div className={styles.chatRoomHeader}>
                                        <span>日時</span>
                                        <span>タイトル</span>
                                        <span>教材</span>
                                        <span>質問種別</span>
                                        <span>メッセージ数</span>
                                    </div>
                                    <div className={styles.roomContainer}>
                                        {pagenatedChatRooms.map((chatRoom, index) => (
                                            <div
                                                key={`${index}-${chatRoom.conversationId}`}
                                                className={`${styles.room} ${selectedRoom === chatRoom.conversationId ? styles.active : ''}`}
                                                onClick={() => toggleRoom(chatRoom)}
                                            >
                                                <span>{chatRoom.dateTime}</span>
                                                <span className={styles.roomTitle}>{chatRoom.title}</span>
                                                <span>{chatRoom.part}</span>
                                                <span>{chatRoom.type === '0' ? 'チャットボット' : 'コードレビュー'}</span>
                                                <span>{chatRoom.messageCount}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {selectedRoom && chatHistoryData.length > 0 &&
                                <div className={styles.chatHistory} onClick={(event) => event.stopPropagation()}>
                                    <div className={styles.chatHistoryHeader}>
                                        <div>
                                            <IoChatbubblesOutline size={18} />
                                            <span>会話履歴</span>
                                        </div>
                                        <RxCross2 size={18} className={styles.closeButton} onClick={closeChat} />
                                    </div>
                                    <div className={styles.chatHistoryContents}>
                                        {chatHistoryData.map((message, index) => (
                                            <div key={index} className={styles.messageContainer}>
                                                <div className={`${styles.message} ${styles.user}`}>
                                                    {message.userMessage}
                                                </div>
                                                <div className={`${styles.message} ${styles.bot}`}>
                                                    <ChatResponseMarkdown>{message.botMessage}</ChatResponseMarkdown>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            <div className={styles.paginationContainer}>
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={Math.ceil(chatRoomList?.length / itemsPerPage)}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </>
                    ) : (
                        <div className={styles.noData}>
                            まだ質問がされていません。
                        </div>
                    )
                )}
            </div>
        </>
    );
};

export default UsersQuestionHistoryCard;
