import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/Statistics_User.module.css';
import { fetchPartData, fetchUserProgress } from '../api/statistics';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StatisticsUser = () => {
    const navigate = useNavigate();
    const [partData, setPartData] = useState([]);
    const [selectedPart, setSelectedPart] = useState('');
    const [startMonth, setStartMonth] = useState('');
    const [startYear, setStartYear] = useState('');
    const [endMonth, setEndMonth] = useState('');
    const [endYear, setEndYear] = useState('');
    const graphData = { labels: [], datasets: [{ data: [] }] };

    // Generate months from current date back to one year ago
    const generateMonths = () => {
        const months = [];
        const currentDate = new Date();
        for (let i = 0; i < 12; i++) {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // getMonth() returns 0-11
            months.push({ year, month, name: `${year}年${month}月` });
        }
        return months;
    };

    const months = generateMonths();

    useEffect(() => {
        const fetchPartDataAsync = async () => {
            try {
                const partData = await fetchPartData(navigate);
                setPartData(partData);
            } catch (error) {
                console.error('APIエラー：パートデータの取得', error);
            }
        };
        fetchPartDataAsync();
    }, [navigate]);

    const handlePartChange = (partId) => {
        setSelectedPart(partId);
        searchUserProgress();
    };

    const handleStartMonthChange = (e) => {
        setStartYear(e.target.value.split(',')[0]);
        setStartMonth(e.target.value.split(',')[1]);
        searchUserProgress();
    };

    const handleEndMonthChange = (e) => {
        setEndYear(e.target.value.split(',')[0]);
        setEndMonth(e.target.value.split(',')[1]);
        searchUserProgress();
    };

    const searchUserProgress = async () => {
        try {
            const userProgress = await fetchUserProgress(
                navigate,
                startYear,
                startMonth,
                endYear,
                endMonth,
                selectedPart
            );
            graphData.labels = userProgress.map((item) => item.month);
            graphData.datasets[0].data = userProgress.map((item) => item.count);
            console.log(graphData);
        } catch (error) {
            console.error('APIエラー：ユーザー進捗の取得', error);
        }

    };

    return (
        <>
            <div className={styles.SelecterContainer}>
                <select
                    className={`${styles.startMonthSelecter} ${styles.Select}`}
                    value={startMonth}
                    onChange={handleStartMonthChange}
                >
                    {months.map((month) => (
                        <option
                            key={`start${month.year}${month.month}`}
                            value={`startYear:${month.year},startMonth:${month.month}`}
                        >
                            {month.name}
                        </option>
                    ))}
                </select>
                <div className={styles.to}>～</div>
                <select
                    className={`${styles.endMonthSelecter} ${styles.Select}`}
                    value={endMonth}
                    onChange={handleEndMonthChange}
                >
                    {months.map((month) => (
                        <option
                            key={`end${month.year}${month.month}`}
                            value={`endYear:${month.year},endMonth:${month.month}`}
                        >
                            {month.name}
                        </option>
                    ))}
                </select>
                <select
                    className={`${styles.partSelecter} ${styles.Select}`}
                    value={selectedPart}
                    onChange={(e) => handlePartChange(e.target.value)}
                >
                    {partData?.map((part) => (
                        <option key={part.partId} value={part.partId}>{part.partTitle}</option>
                    ))}
                </select>
            </div>
        </>
    );
};

export default StatisticsUser;