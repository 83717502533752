import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/UsersProgress.module.css';
import userPageStyles from '../css/UserPage.module.css';
import { fetchPartsProgressData, fetchSectionsProgressData } from '../api/userProgress';
import { convertArticleStatusJson } from '../utils/ConvertArticleStatusJson';
import { IoChevronForward, IoChevronDownSharp } from "react-icons/io5";
import { FiCircle } from "react-icons/fi";
import { GoCheckCircleFill } from "react-icons/go";

const UsersProgressCard = ({ data, partsList, setPartsList }) => {
    const navigate = useNavigate();

    //--------------------------------------------教材選択--------------------------------------------
    const [selectedPart, setSelectedPart] = useState(null);
    //セクションの進捗率
    const [sectionsProgress, setSectionsProgress] = useState(null);
    //進捗バーの存在
    const [barExist, setBarExist] = useState(false);
    //進捗バーのパーセンテージ
    const [percentage, setPercentage] = useState(0);
    //チャプターの表示・非表示
    const [openChapters, setOpenChapters] = useState({});
    //セクションの表示・非表示
    const [openSections, setOpenSections] = useState({});
    //検索ワード
    const [searchTerm, setSearchTerm] = useState('');
    //フィルタリングされたパート
    const [filteredParts, setFilteredParts] = useState([]);
    //エラーメッセージ
    const [errorMessage, setErrorMessage] = useState('');
    //ドロップダウンの開閉
    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    // //教材リスト
    // const [partsList, setPartsList] = useState([]);
    //strapi画像URL
    const strapiImageUrl = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_ENDPOINT_GET_IMAGE}?id=`;
    // 「進捗」でチャプターの表示・非表示を切り替えるハンドラ
    const toggleChapter = (chapterId) => {
        setOpenChapters(prevState => ({
            ...prevState,
            [chapterId]: !prevState[chapterId]
        }));
    };

    // 「進捗」でセクションの表示・非表示を切り替えるハンドラ
    const toggleSection = (sectionId) => {
        setOpenSections(prevState => ({
            ...prevState,
            [sectionId]: !prevState[sectionId]
        }));
    };

    // 「進捗」で教材の選択を変更するハンドラ
    const handleSelectChange = (part) => {
        setErrorMessage("");
        setBarExist(true);
        setSelectedPart(part);
        setPercentage(part.progressRate);
    };

    // 「進捗」で入力フィールドの変更に基づくフィルタリング
    const handleInputChange = (e) => {
        // setIsDropdownOpen(true);
        const value = e.target.value;
        setSearchTerm(value);
        const filtered = partsList.filter(part =>
            part.partTitle.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredParts(filtered);
    };

    // 「進捗」でドロップダウンリストのオプションをクリックしたときの処理
    const handleOptionClick = (part) => {
        handleSelectChange(part);
        setSearchTerm(part.partTitle);
        // setIsDropdownOpen(false);
    };

    //--------------------------------------------初期ロード--------------------------------------------
    // 「進捗」でパートリストとユーザーの進捗率を取得
    const fetchPartsProgress = useCallback(async () => {
        if (data.id) {
            try {
                const partsProgressData = await fetchPartsProgressData(navigate, data.id);
                if (partsProgressData.length > 0) {
                    const mappedPartsList = partsProgressData.map(part => ({
                        id: part.id,
                        partTitle: part.title,
                        image: part.image,
                        progressRate: part.progressRate,
                        completedArticles: part.completedArticles,
                        totalArticles: part.totalArticles
                    }));
                    setPartsList(mappedPartsList);
                    setFilteredParts(mappedPartsList);
                    setSelectedPart(mappedPartsList[0]);
                    setBarExist(true);
                    setPercentage(mappedPartsList[0].progressRate);
                } else {
                    setErrorMessage("データベースに欠損があります。");
                }
            } catch (error) {
                console.error('APIエラー：パート別の進捗率の取得', error);
            }
        }
    }, [data.id, setPartsList, navigate]);

    useEffect(() => {
        fetchPartsProgress();
    }, [fetchPartsProgress]);

    // 「進捗」でセクション別の進捗データを取得
    const fetchSectionsProgress = useCallback(async () => {
        if (data.id && selectedPart.id) {
            try {
                const sectionsData = await fetchSectionsProgressData(navigate, data.id, selectedPart.id);
                setSectionsProgress(convertArticleStatusJson(sectionsData));
            } catch (error) {
                console.error('APIエラー：セクション別の進捗率の取得', error);
            }
        }
    }, [data.id, selectedPart, navigate]);

    // 「進捗」で選択された教材が変更された場合に進捗データを更新
    useEffect(() => {
        if (selectedPart) {
            fetchSectionsProgress();
        }
    }, [fetchSectionsProgress, selectedPart, percentage]);


    return (
        <>
            <div className={userPageStyles.ChannelContainerRight}>
                <div className={userPageStyles.customSelect} ref={dropdownRef}>
                    <div className={userPageStyles.selecterInputWrapper}>
                        <input
                            type="text"
                            className={userPageStyles.selecterInput}
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="全て"
                        />
                        <span className={userPageStyles.dropdownArrow}>▾</span>
                    </div>
                    <div className={styles.cards}>
                        {filteredParts.length > 0 && (
                            <>
                                {filteredParts.map((part) => (
                                    <div
                                        key={part.id}
                                        className={`${styles.DropdownMenuItem} ${selectedPart && selectedPart.id === part.id ? styles.selected : ''}`}
                                        onClick={() => handleOptionClick(part)}
                                    >
                                        <div className={styles.imageContainer}>
                                            <div
                                                className={styles.imageCircle}
                                                style={{ border: `3px solid '#e5e7eb'` }}
                                            >
                                                <img
                                                    src={part.image ? `${strapiImageUrl}${part.image}` : ''}
                                                    alt={part.partTitle}
                                                    className={styles.DropdownMenuItemImage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {selectedPart && (
                <div className={styles.ValueBox}>
                    <div className={styles.ValueBoxTitle}>
                        <h1 className={styles.ValueBoxTitleText}>{selectedPart.partTitle}</h1>
                    </div>
                    {selectedPart && (
                        <div className={styles.ProgressBarBox}>
                            <div className={styles.ProgressBarTitleBox}>
                                <div className={styles.title}>進捗状況</div>
                                <div className={styles.ProgressPercentage}>
                                    {Math.floor(selectedPart.progressRate)}%（{selectedPart.completedArticles}/{selectedPart.totalArticles}問完了）
                                </div>
                            </div>
                            <div className={styles.ProgressBarValue}>
                                <div className={styles.ProgressBar} key={`selectedPart-${selectedPart.id}`}>
                                    {barExist && (
                                        <div
                                            className={styles.completeBar}
                                            style={{ width: `${Math.floor(percentage)}%` }}
                                        ></div>
                                    )}
                                    {errorMessage && <div className={styles.ErrorMessage}>{errorMessage}</div>}
                                </div>
                            </div>
                        </div>
                    )}

                    {sectionsProgress && sectionsProgress.map((part) => (
                        part.partId === selectedPart.id && (
                            <div key={part.partId} className={styles.part}>
                                <div className={styles.title}>
                                    詳細学習状況
                                </div>
                                <div className={styles.chapterContainer}>
                                    {part.chapters.map(chapter => (
                                        <div key={chapter.chapterId} >
                                            <div className={`${styles.chapterBox} ${styles.classHover} ${chapter.chapterStatus === '完了' ? styles.completeColor : ''}`} onClick={() => toggleChapter(chapter.chapterId)}>
                                                <div className={styles.chapterIcon}>
                                                    {chapter.chapterStatus === '完了' ? <GoCheckCircleFill className={styles.checkIcon} /> : <FiCircle className={styles.unCheckIcon} />}
                                                </div>
                                                <div className={styles.chapterTitleBox} >
                                                    <div className={styles.Title}>
                                                        {chapter.chapterTitle}
                                                    </div>
                                                </div>
                                                <div className={styles.chapterStatusBox}>
                                                    <div className={styles.chapterStatus}>
                                                        <div className={styles.chapterStatusValue}>
                                                            {chapter.sections.flatMap(section => section.articles).filter(article => article.articleStatus === '完了').length}
                                                            /
                                                            {chapter.sections.flatMap(section => section.articles).length}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.topBottomIconBox}>
                                                    <div className={styles.topBottom}>
                                                        {openChapters[chapter.chapterId] ? <IoChevronDownSharp className={styles.upIcon} /> : <IoChevronForward className={styles.downIcon} />}
                                                    </div>
                                                </div>
                                            </div>
                                            {openChapters[chapter.chapterId] && chapter.sections.map(section => (
                                                <div key={`${chapter.chapterId}-${section.sectionId}`}>
                                                    <div className={`${styles.sectionSectorBox} ${styles.classHover}`} onClick={() => toggleSection(section.sectionId)}>
                                                        <div className={styles.sectionIconBox}>
                                                            {section.sectionStatus === '完了' ? <GoCheckCircleFill className={`${styles.checkIcon} ${styles.sectionCheckIcon}`} /> : <FiCircle className={`${styles.unCheckIcon} ${styles.sectionUnCheckIcon}`} />}
                                                        </div>
                                                        <div className={styles.sectionTitleBox}>
                                                            <div className={styles.Title}>
                                                                {section.sectionTitle}
                                                            </div>
                                                        </div>
                                                        <div className={`${styles.sectionStatusBox} ${section.sectionStatus === '完了' ? styles.complete : styles.incomplete}`}>
                                                            <div className={styles.sectionStatus}>
                                                                {/* <div className={styles.sectionStatusName}>
                                                                {section.sectionStatus}
                                                            </div> */}
                                                                <div className={styles.sectionStatusValue}>
                                                                    {section.articles.filter(article => article.articleStatus === '完了').length}/{section.articles.length}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.topBottomBoxIconBox}>
                                                            <div className={styles.topBottom}>
                                                                {openSections[section.sectionId] ? <IoChevronDownSharp className={styles.upIcon} /> : <IoChevronForward className={styles.downIcon} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {openSections[section.sectionId] && section.articles.map(article => (
                                                        <div key={article.articleId} className={styles.articleBox}>
                                                            <div className={styles.articleIcon}>
                                                                {article.articleStatus === '完了' ? <GoCheckCircleFill className={`${styles.checkIcon} ${styles.articleCheckIcon}`} /> : <FiCircle className={`${styles.unCheckIcon} ${styles.articleUnCheckIcon}`} />}
                                                            </div>
                                                            <div className={styles.articleTitleBox}>
                                                                <div className={`${styles.articleTitle} ${styles.Title}`}>{article.articleTitle}</div>
                                                                <div className={`${styles.articleStatus} ${article.articleStatus === '完了' ? styles.complete : styles.incomplete}`}>
                                                                    <span>{article.articleStatus}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    ))}
                </div>
            )}
        </>
    );
};

export default UsersProgressCard;